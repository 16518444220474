import React, { useEffect, useState, ReactNode, useRef } from 'react'

interface ResponsiveMapContainerProps {
  children: ReactNode
  hasFooter?: boolean
  onHeightChange?: (height: string) => void
}

const ResponsiveMapContainer = ({
  children,
  hasFooter,
}: ResponsiveMapContainerProps) => {
  const [mapHeight, setMapHeight] = useState<string>('45vh') // Start taller than we need

  useEffect(() => {
    const calculateMapHeight = (): void => {
      const viewportHeight: number = window.innerHeight
      const viewportWidth: number = window.innerWidth
      const aspectRatio: number = viewportHeight / viewportWidth

      // Calculating final heights - same as before but slightly smaller
      if (aspectRatio > 1.9) {
        const minPanelVisiblePx: number = hasFooter ? 280 : 250
        const minPanelVh: number =
          (minPanelVisiblePx / viewportHeight) * 100
        const mapVh: number = 100 - minPanelVh
        setMapHeight(`${Math.min(38, mapVh)}vh`) // Slightly smaller than before
      } else if (aspectRatio > 1.6) {
        setMapHeight(hasFooter ? '35vh' : '33vh')
      } else {
        setMapHeight(hasFooter ? '33vh' : '31vh')
      }
    }

    // Small delay to let initial render complete
    const timer = setTimeout(calculateMapHeight, 100)
    return () => clearTimeout(timer)
  }, [hasFooter])

  return (
    <div
      className="block w-full overflow-scroll mt-3"
      style={{ height: mapHeight }}
    >
      {children}
    </div>
  )
}

export default ResponsiveMapContainer

import { useEffect, useRef, useState } from 'react'
import { orderStatusWording } from './constants'
import { Fulfillment, OrderDataInterface } from './types'
import attachAtlas from './atlas'
import { useQuery } from '@tanstack/react-query'
import { fetchOrder } from './api'

export function useOrderStatus(fulfillment?: Fulfillment) {
  const [orderStatus, setOrderStatus] = useState('')
  const [iconColor, setIconColors] = useState(0)
  useEffect(() => {
    /* V2 Statuses */

    const pickupIsInPast =
      fulfillment?.runner_est_pickup_at &&
      new Date(fulfillment?.runner_est_pickup_at) < new Date()

    if (fulfillment?.status === 'pending_vendor' && !pickupIsInPast) {
      setOrderStatus(orderStatusWording['CONFIRMING'])
    }
    const pendingAssginStatuses = [
      'pending_dispatch',
      'staging',
      'pending_reassign',
    ] as const

    if (
      pendingAssginStatuses.includes(fulfillment?.status as any) ||
      pickupIsInPast
    ) {
      setOrderStatus(orderStatusWording['FINDING_DRIVER'])
      setIconColors(-1)
      return
    }
    if (fulfillment?.runner_assigned_at) {
      setOrderStatus(orderStatusWording['EN_ROUTE_TO_PICK_UP'])
      setIconColors(0)
    }
    if (fulfillment?.runner_arrived_pickup) {
      setOrderStatus(orderStatusWording['ARRIVED_AT_PICKED'])
      setIconColors(1)
    }
    if (fulfillment?.runner_picked_up) {
      setOrderStatus(orderStatusWording['PICKED_UP'])
      setIconColors(2)
    }
    if (fulfillment?.runner_arrived_dropoff) {
      setOrderStatus(orderStatusWording['ARRIVED_DROPPED_OFF'])
      setIconColors(3)
    }
    if (fulfillment?.runner_dropped_off) {
      setOrderStatus(orderStatusWording['DROPPED_OFF'])
    }
    if (fulfillment?.status === 'completed') {
      setOrderStatus('Completed')
    }
    if (fulfillment?.status === 'cancelled') {
      setOrderStatus('Cancelled')
    }
  }, [fulfillment])
  return { orderStatus, iconColor }
}

export const useAtlasTracking = (data: OrderDataInterface) => {
  useEffect(() => {
    const win = window as any
    if (!win.Atlas) {
      // Assume attachAtlas() is a function to setup Atlas which you would import
      attachAtlas()
    }

    if (data) {
      if (data?.operator?.customer_support_tracking) {
        win.Atlas?.start({
          recording: {
            enabled: false,
          },
        })

        const atlasData = {
          userId: data?.order?.id || data?.customer?.address,
          name: data?.customer?.name,
          email: data?.merchant?.name,
          merchant_name: data?.merchant?.name,
          //these neet to registered in the 'Customer' section at https://app.atlas.so/configuration/custom-fields
          //the fields will then appear beneath the 'Customer' card in a ticket (or Customers table)
          customFields: {
            operator: data?.delivery_service?.name,
            entity: 'diner',
            territory: data?.merchant?.territory,
          },
        }

        win.Atlas?.identify(atlasData)
      }
    }
  }, [data]) // You might need to be more specific with dependencies depending on your data structure
}

export const useOrderData = (orderId: string) => {
  const { data, isLoading, isError, error, refetch, ...rest } =
    useQuery<OrderDataInterface, Error>({
      queryKey: [orderId],
      queryFn: () => fetchOrder(orderId), // Implement fetchOrder to fetch order data
      staleTime: 5000,
      refetchInterval: 10000,
      enabled: !!orderId,
    })

  const { orderStatus, iconColor } = useOrderStatus(data?.fulfillment)
  const timelineEvents = useCreateOrderTimeline(
    data?.fulfillment || []
  )

  const fulfillment = data?.fulfillment
  const delivery_service = data?.delivery_service
  const merchant = data?.merchant
  const runner = data?.runner
  const order = data?.order
  const customer = data?.customer

  const fallbackColor = '#1F5EA9'
  const primaryColor =
    delivery_service?.colors?.primary || fallbackColor
  const containerRef = useRef(null)

  useAtlasTracking(data!)

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
    orderStatus,
    customer,
    iconColor,
    timelineEvents,
    fulfillment,
    delivery_service,
    merchant,
    runner,
    order,
    primaryColor,
    fallbackColor: '#1F5EA9',
    container: containerRef,
    ...rest,
  }
}

export function useCreateOrderTimeline(fulfillment: any) {
  const getStatusTime = (options: string[]) => {
    for (const option of options) {
      if (fulfillment[option]) return fulfillment[option]
    }
    return '' // Default if no timestamps are found
  }

  const timelineEvents = [
    {
      statusText: 'Order Placed',
      keys: ['created_at', 'pending_assign'],
    },
    {
      statusText: 'En Route to Pickup',
      keys: ['runner_assigned_at', 'en_route_pickup'],
    },
    {
      statusText: 'Arrived at Pickup',
      keys: ['runner_arrived_pickup', 'arrived_pickup'],
    },
    {
      statusText: 'En Route to You',
      keys: ['runner_picked_up', 'picked_up'],
    },
    {
      statusText: 'Arriving Now',
      keys: ['runner_arrived_dropoff', 'arrived_dropoff'],
    },
    {
      statusText: 'Delivered',
      keys: ['runner_dropped_off', 'completed'],
    },
  ]

  if (fulfillment.status === 'cancelled') {
    timelineEvents.push({
      statusText: 'Cancelled',
      keys: ['cancelled'],
    })
  }

  const timeline = timelineEvents
    .map(event => ({
      statusText: event.statusText,
      statusTime: getStatusTime(event.keys),
    }))
    .filter(
      event => event.statusTime || event.statusText === 'Cancelled'
    ) // Ensure cancelled events are included without requiring a timestamp (for V2 orders)

  return timeline
}

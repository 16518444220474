/* eslint-disable @next/next/no-img-element */

import { useRef } from 'react'
import {
  AddressIconWrapper,
  AddressTitleWrapper,
  IconBox,
  OrderStatusMobileETASpan,
  OrderStatusMobileETAText,
} from 'styles/orderStatus'
import Spinner from '../Spinner'
import Building from '../Icons/Building'
import Phone from '../Icons/Phone'
import MapBox from '../MapBox'
import { useRouter } from 'next/router'
import classNames from '../classNames'
import FooterImage from '../FooterImage'
import SlideUpPanel from '../SlideUpPanel'
import StatusBars from './StatusBars'
import Timeline from 'components/Timeline'
import CarFront from 'components/Icons/CarFront'
import OrderDetails from 'components/OrderDetails'
import { useOrderData } from 'components/effects'
import {
  formatEstimatedRange,
  formatEstimatedTime,
} from 'components/format'
import moment from 'moment'
import ResponsiveMapContainer from '../ResponsiveMapContainer'

const OrderStatus = () => {
  const router = useRouter()
  const { orderId } = router.query as { orderId: string }
  const mapRef = useRef<any>(null)
  const {
    data,
    isLoading,
    isError,
    timelineEvents,
    primaryColor,
    container,
    delivery_service,
    fulfillment,
    merchant,
    runner,
    orderStatus,
    iconColor,
  } = useOrderData(orderId)

  const order = data?.order
  if (isLoading) {
    return (
      <div ref={container}>
        <div className="flex flex-col items-center justify-center h-screen">
          <h3 className="mb-5 text-2xl font-normal text-gray-700 text-opacity-80 ">
            Loading order...
          </h3>

          <div>
            <Spinner />
          </div>
        </div>
      </div>
    )
  }

  return !order ? null : (
    <div className="w-full max-w-lg">
      {delivery_service?.icon && (
        <div className="w-full relative pb-[25%]">
          <img
            src={delivery_service?.icon}
            alt="delivery service logo"
            className="absolute top-0 left-0 w-full h-full object-contain"
          />
        </div>
      )}
      <ResponsiveMapContainer
        hasFooter={!!delivery_service?.footer_image}
        onHeightChange={() => {
          if (mapRef.current) {
            setTimeout(() => {
              mapRef.current.resize()
            }, 100)
          }
        }}
      >
        {data && <MapBox data={data} />}
      </ResponsiveMapContainer>
      {/* @ts-ignore */}
      <SlideUpPanel hasFooter={delivery_service?.footer_image}>
        <div className="flex flex-row items-center flex-grow">
          {/* STATUS */}
          <div
            className={classNames(
              `flex flex-col flex-grow justify-center w-1/2 font-semibold text-[20px] max-xs:text-[16px]`
            )}
            color={
              fulfillment?.status === 'cancelled'
                ? 'red'
                : `${'#191919'}`
            }
          >
            {orderStatus}
            <span className="text-base font-normal">
              {iconColor === -1
                ? `Order Placed ${moment(
                    data?.fulfillment?.created_at
                  ).format('h:mm a')}`
                : `Est. Delivery Time ${formatEstimatedTime(data)}`}
            </span>
          </div>
          {data &&
            data.fulfillment.status !== 'completed' &&
            data.fulfillment.status !== 'cancelled' && (
              <span
                className={classNames(
                  'flex items-center justify-center ml-auto px-2.5 py-[5px] rounded-[10px] text-[20px] max-xs:text-[16px]'
                )}
                style={{ backgroundColor: primaryColor }}
              >
                <OrderStatusMobileETAText color={`${'#FFFFFF'}`}>
                  <OrderStatusMobileETASpan>
                    {formatEstimatedRange(data)}
                  </OrderStatusMobileETASpan>
                </OrderStatusMobileETAText>
              </span>
            )}
        </div>
        <div className="pb-1">
          <StatusBars
            fulfillment={fulfillment}
            iconColor={iconColor}
            primaryColor={primaryColor}
          />
        </div>
        {merchant?.name ? (
          <div className="border-t py-3 border-[#D3D3D3]">
            <AddressTitleWrapper>
              <Building color={`#5C6067`} width={32} height={25} />
              <span className="ml-3.5 text-lg font-bold">
                {merchant?.name}
              </span>
              <AddressIconWrapper>
                {merchant!.phone && (
                  <IconBox color={`${primaryColor}`}>
                    <a href={`tel:${merchant?.phone}`}>
                      <Phone />
                    </a>
                  </IconBox>
                )}
              </AddressIconWrapper>
            </AddressTitleWrapper>
          </div>
        ) : null}
        <div className="border-t py-3 border-[#D3D3D3]">
          <AddressTitleWrapper>
            <div className="ml-[3px] mb-[2px]">
              <CarFront color={`${primaryColor}`} />
            </div>
            <span className="ml-5 text-base font-medium capitalize">
              {runner?.name}
            </span>
            <AddressIconWrapper>
              {runner?.phone && (
                <IconBox color={`${primaryColor}`}>
                  <a href={`tel:${runner?.phone}`}>
                    <Phone />
                  </a>
                </IconBox>
              )}
            </AddressIconWrapper>
          </AddressTitleWrapper>
        </div>
        <div className="border-t py-3 border-[#D3D3D3]">
          <span className="font-medium text-gray-600 ">TIMELINE</span>
          <div className="mt-3">
            <Timeline items={timelineEvents} />
          </div>
        </div>
        {order.details || order.payment ? (
          <div className="border-t py-3 border-[#D3D3D3]">
            <span className="font-medium text-gray-600 ">
              ORDER DETAILS
            </span>
            <div className="mt-3">
              <OrderDetails
                tip={order.payment.tip}
                subtotal={order.payment.subtotal}
                items={order.details}
              />
            </div>
          </div>
        ) : null}
        {/* Bottom padding so that footer image doesn't block the order details */}
        {delivery_service?.footer_image && <div className="pb-5" />}
      </SlideUpPanel>
      {delivery_service?.footer_image && (
        <div className="fixed inset-x-0 bottom-0 z-20">
          <FooterImage
            image={delivery_service.footer_image}
            link={delivery_service.footer_link}
          />
        </div>
      )}
    </div>
  )
}

export default OrderStatus
